.second-content {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../../shared/asset/Leciel/main_banner_test.jpg');
    background-size: cover;
    background-position: center;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.6) 70%,
        rgba(0, 0, 0, 0.8) 85%
      );
    }
  }

  .content {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    .text-content {
      max-width: 800px;
      padding: 0 120px;
      color: white;
     
      .main-title {
        font-size: 72px;
        font-weight: 600; 
        margin-bottom: 80px; 
        letter-spacing: 4px; 
        color: rgba(255, 255, 255, 1); 
        text-transform: uppercase;
        line-height: 1.2;
      }
     
      .service-items {
        display: flex;
        flex-direction: column;
        gap: 60px;
     
        .service-item {
          display: flex;
          gap: 32px; 
          align-items: flex-start;
     
          .number {
            font-size: 96px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.7);
            line-height: 1;
            font-family: 'Montserrat', sans-serif;
          }
     
          .text {
            h3 {
              font-size: 28px;
              font-weight: 500;
              margin-bottom: 20px;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 2px;
              text-transform: uppercase;
            }
     
            p {
              font-size: 17px;
              line-height: 1.9;
              color: rgba(255, 255, 255, 0.85);
              margin-bottom: 12px;
              font-weight: 300;
              letter-spacing: 0.5px; 
     
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
     

          &:hover {
            .number {
              transition: color 0.3s ease;
            }
            .text h3 {
              transform: translateX(10px);
              transition: transform 0.3s ease;
            }
          }
        }
      }
     }
     

     @media (max-width: 1200px) {
      .text-content {
        padding: 0 80px;
     
        .main-title {
          font-size: 60px;
          margin-bottom: 60px;
        }
      }
     }
     
     @media (max-width: 768px) {
      .text-content {
        padding: 0 30px;
     
        .main-title {
          font-size: 48px;
          margin-bottom: 50px;
          letter-spacing: 2px;
        }
     
        .service-items {
          gap: 40px;
     
          .service-item {
            gap: 20px;
     
            .number {
              font-size: 72px;
            }
     
            .text {
              h3 {
                font-size: 22px;
                margin-bottom: 15px;
              }
     
              p {
                font-size: 15px;
                line-height: 1.8;
              }
            }
          }
        }
      }
     }
    }
  }