.reservation {
  background: linear-gradient(160deg, #1a1a1a 0%, #262626 100%); /* 배경을 그라데이션으로 변경 */
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #f1f1f1; /* 텍스트 색상을 밝은 색으로 변경 */
  height: 100vh; /* 화면 높이에 맞게 설정 */
  background-size: cover; /* 배경을 화면에 맞게 확장 */
  background-position: center; /* 배경이 중앙에 오도록 설정 */
  min-height: 100vh; /* 화면의 최소 높이를 100%로 설정 */
  overflow: auto; /* 내용이 넘칠 경우 스크롤 허용 */

  .reservation-header {
    text-align: center;
    padding-top: 200px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    color: white;
    border-radius: 12px 12px 0 0;

    h1 {
      font-size: 48px;
      margin: 0;
      letter-spacing: 2px;
    }

    p {
      font-size: 20px;
      margin-top: 10px;
    }
  }

  .reservation-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 30px auto;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.1); /* 배경색을 투명하게 설정 */
    border-radius: 12px;

    .form-group {
      margin-bottom: 25px;

      label {
        font-size: 18px;
        margin-bottom: 8px;
        display: block;
        color: #f1f1f1;
      }

      input,
      select {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border: 1px solid rgba(255, 255, 255, 0.3); /* 테두리 색상 밝은 회색으로 설정 */
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        outline: none;
        background-color: rgba(255, 255, 255, 0.2); /* 배경색을 투명하게 설정 */
      }

      .gender-options {
        display: flex;
        gap: 50px;
        align-items: center;

        label {
          display: flex;
          padding-top: 10px;
          align-items: center;
          font-size: 16px;
          white-space: nowrap;

          input[type="radio"] {
            margin-right: 20px;
            transform: scale(1.3);
          }
        }
      }
    }

    .agreement,
    .info {
      margin-bottom: 25px;

      p {
        font-size: 16px;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.7); /* 텍스트 색상 밝게 */
      }

      textarea {
        width: 100%;
        height: 120px;
        padding: 12px;
        font-size: 14px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        resize: none;
        transition: all 0.3s ease-in-out;
        background-color: rgba(255, 255, 255, 0.2);

      }

      label {
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        margin-top: 10px;

        input {
          margin-right: 12px;
        }
      }
    }

    .submit-btn {
      background-color: #ffffff;
      color: #1a1a1a;
      font-size: 20px;
      padding: 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #444444;
        color: #ffffff;
        transform: translateY(-2px); /* 버튼 hover 시 살짝 위로 이동 */
      }
    }
  }
}
