@import '../../../shared/styles/variables'; 

.business-overview {
  width: 100%;
  max-width: $desktop;
  margin: 0 auto;
  padding: 60px 20px 120px;

  @media (max-width: $mobile) {
    padding: 30px 15px 60px;
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 60px;
    color: #333;
    position: relative;
    
    @media (max-width: $mobile) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 3px;
      background-color: $primary-color;

      @media (max-width: $mobile) {
        width: 30px;
        bottom: -15px;
      }
    }
  }

  .main-image-container {
    margin-bottom: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    
    @media (max-width: $mobile) {
      margin-bottom: 30px;
      border-radius: 4px;
    }
    
    .main-image {
      width: 100%;
      height: auto;
      display: block;
    }

    .thumbnail-container {
      padding: 20px;
      background: #f8f9fa;
      display: flex;
      gap: 15px;
      border-top: 1px solid #eee;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      @media (max-width: $mobile) {
        padding: 15px;
        gap: 10px;
      }

      .thumbnail {
        flex: 0 0 auto;  // 모바일에서 고정 크기 유지
        width: 120px;
        height: 80px;
        object-fit: cover;
        border-radius: 6px;
        cursor: pointer;
        opacity: 0.6;
        transition: all $transition-duration ease;
        border: 2px solid transparent;

        @media (max-width: $mobile) {
          width: 100px;
          height: 60px;
          border-radius: 4px;
        }

        &:hover {
          opacity: 0.8;
        }

        &.active {
          opacity: 1;
          border-color: $primary-color;
        }
      }
    }
  }

  .info-table {
    width: 100%;
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;

    @media (max-width: $mobile) {
      display: block;
      
      tbody, tr, th, td {
        display: block;
      }

      tr {
        padding: 15px 0;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    th, td {
      padding: 25px;
      border-bottom: 1px solid #eee;
      line-height: 1.6;

      @media (max-width: $mobile) {
        padding: 5px 15px;
        border-bottom: none;
      }
    }

    th {
      width: 180px;
      background-color: #f8f9fa;
      font-weight: 600;
      text-align: center;
      color: #333;
      position: relative;

      @media (max-width: $mobile) {
        width: auto;
        text-align: left;
        background-color: transparent;
        padding-bottom: 5px;
        font-size: 14px;
        color: #666;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 60%;
        background-color: #eee;

        @media (max-width: $mobile) {
          display: none;
        }
      }
    }

    td {
      color: #555;
      font-size: 15px;

      @media (max-width: $mobile) {
        font-size: 16px;
        font-weight: 500;
        padding-top: 0;
      }
    }

    tr:last-child {
      th, td {
        border-bottom: none;
      }
    }
  }
}