@import '../../../shared/styles/variables';

.입주자모집공고 {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  background-color: #fff;

  @media (max-width: $mobile) {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }

  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    color: #292929;
    letter-spacing: -0.5px;

    @media (max-width: $mobile) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .content-container {
    display: flex;
    max-width: $desktop;
    margin: 0 auto;
    height: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    @media (max-width: $mobile) {
      flex-direction: column;
      height: auto;
    }

    .image-section {
      flex: 1;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0,0,0,0.02), rgba(0,0,0,0));
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      @media (max-width: $mobile) {
        height: 300px;
      }
    }

    .info-section {
      width: 460px;
      background-color: #e1e1e1;
      display: flex;
      align-items: center;
      padding: 0 60px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0.02));
      }

      .info-content {
        width: 100%;

        .project-name {
          font-size: 18px;
          color: #666;
          margin-bottom: 15px;
          letter-spacing: -0.3px;
        }

        h3 {
          font-size: 32px;
          font-weight: 600;
          color: $primary-color;
          margin-bottom: 50px;
          letter-spacing: -0.5px;
          line-height: 1.3;
        }

        button {
          width: 100%;
          padding: 18px 0;
          background-color: $primary-color;
          color: #fff;
          border: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.3px;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .arrow {
            position: absolute;
            right: 25px;
            font-size: 20px;
            font-weight: 300;
          }

          &:hover {
            background-color: darken($primary-color, 5%);
          }
        }
      }

      @media (max-width: $mobile) {
        width: 100%;
        padding: 40px 20px;

        .info-content {
          text-align: center;

          .project-name {
            font-size: 16px;
            margin-bottom: 10px;
          }

          h3 {
            font-size: 26px;
            margin-bottom: 30px;
          }

          button {
            padding: 15px 0;
            font-size: 15px;

            .arrow {
              right: 20px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}