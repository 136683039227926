@import '../../../shared/styles/variables';

.xi-mobile-home {
  width: 100%;
  max-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .visual-section {
    position: relative;
    background: #fff;
    
    .carousel-container {
      width: 100%;
      height: 31vh;
      position: relative;
      overflow: hidden;

      .slide {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .indicator {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 8px;
      padding: 0 20px;
      z-index: 10;

      .dot {
        width: 40px;
        height: 3px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        position: relative;
        cursor: pointer;

        &.active {
          background: rgba(255, 255, 255, 0.8);
        }

        .progress {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background: #fff;
          border-radius: 2px;
        }
      }
    }
  }

  .menu-section {
    flex: 1;
    background: #fff;
    padding: 30px 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -10px 20px rgba(0,0,0,0.05);
    max-height: 50%;
    position: relative;
    z-index: 2;

    .menu-grid {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      max-width: 400px;
      margin: 0 auto;


      .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 20px;
        background: #f8f9fa;
        border-radius: 12px;
        transition: all 0.3s ease;

        &:active {
          transform: scale(0.98);
          background: darken(#f8f9fa, 3%);
        }

        .icon {
          font-size: 24px;
          margin-bottom: 8px;
          width: 48px;
          height: 48px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 8px rgba(0,0,0,0.05);
        }

        span {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.3px;
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .xi-mobile-home {
    .carousel-container {
      height: 45vh;
    }

    .menu-section {
      padding: 20px 15px;

      .menu-grid .menu-item {
        padding: 15px;

        .icon {
          font-size: 20px;
          width: 40px;
          height: 40px;
          margin-bottom: 6px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
}