.xi-layout {
    height: auto;
    display: flex;
    flex-direction: column;
  
    .xi-content {
      flex: 1;
      margin-top: 80px;
    }
  }
  