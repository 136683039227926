@import '../../../shared/styles/variables';

.마감재리스트 {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  background-color: #fff;
  overflow-y: auto;

  // 스크롤바 스타일링
    &::-webkit-scrollbar {
        width: 12px;
      }
  
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }
  
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
  
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  
    @media (max-width: $mobile) {
        height: calc(100vh - 200px);
        padding: 0 15px;
  
    &::-webkit-scrollbar {
          width: 4px;
        }
      }

  @media (max-width: $mobile) {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }
}