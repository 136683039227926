.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 80px;
  transition: all 0.4s ease;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
 
  &.scrolled {
    background: rgba(0, 0, 0, 0.75);
    height: 70px;
  }
 
  .header-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 60px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
  .logo {
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: -0.02em;
    transition: all 0.3s ease;
    
    &:hover {
      opacity: 0.9;
    }
  }
 
  .main-nav {
    display: flex;
    gap: 65px;
    height: 100%;
    margin: 0 40px;
 
    .nav-item {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      
      .category {
        color: rgba(255, 255, 255, 0.9);
        font-size: 17px;
        font-weight: 400;
        cursor: pointer;
        padding: 10px 6px;
        transition: all 0.3s ease;
        letter-spacing: -0.01em;
        position: relative;
 
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: white;
          transform: scaleX(0);
          transition: transform 0.3s ease;
          transform-origin: right;
        }
 
        &:hover {
          color: rgba(255, 255, 255, 1);
          
          &::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
 
      .submenu {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        min-width: 220px;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(10px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        padding: 10px 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
 
        .submenu-item {
          display: block;
          color: rgba(255, 255, 255, 0.8);
          text-decoration: none;
          padding: 12px 24px;
          font-size: 15px;
          font-weight: 300;
          letter-spacing: -0.01em;
          transition: all 0.3s ease;
          text-align: center;
          white-space: nowrap;
 
          &:hover {
            color: rgba(255, 255, 255, 1);
            background: rgba(255, 255, 255, 0.4);
          }
        }
      }
 
      &:hover {
        .submenu {
          visibility: visible;
          opacity: 1;
          transform: translateX(-50%) translateY(0);
        }
      }
    }
  }
 
  .contact {
    color: rgba(255, 255, 255, 0.9);
    font-size: 17px;
    font-weight: 500;
    padding: 10px 24px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 3px;
    transition: all 0.3s ease;
    letter-spacing: 0.02em;
 
    &:hover {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.05);
    }
  }
 }
 
 @media (max-width: 1280px) {
  .header {
    .main-nav {
      gap: 45px;
    }
  }
 }
 
 @media (max-width: 1024px) {
  .header {
    .main-nav {
      display: none;
    }
  }
 }