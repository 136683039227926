// 기본제공품목.scss
@import '../../../shared/styles/variables';

.기본제공품목 {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  background-color: #f8f8f8;

  @media (max-width: $mobile) {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }

  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    color: #333;

    @media (max-width: $mobile) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .image-container {
    width: 100%;
    max-width: $desktop;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: $mobile) {
      padding: 0 15px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      background-color: #fff;
    }
  }
}