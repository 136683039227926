@import '../../../shared/styles/variables'; 

.location-info {
    width: 100%;
    max-width: $desktop;
    margin: 0 auto;
    padding: 60px 20px 120px;
  
    @media (max-width: $mobile) {
      padding: 30px 15px 60px;
    }
  
    .title {
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 60px;
      color: #333;
      position: relative;
      
      @media (max-width: $mobile) {
        font-size: 24px;
        margin-bottom: 40px;
      }
      
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 3px;
        background-color: $primary-color;
      }
    }
  
    .map-container {
      margin-bottom: 40px;
      position: relative;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;
      
      @media (max-width: $mobile) {
        margin-bottom: 30px;
      }
      
      .main-map {
        width: 100%;
        height: auto;
        display: block;
      }
  
      .map-overlay {
        position: absolute;
        bottom: 20px;
        left: 20px;
        background: rgba(255, 255, 255, 0.9);
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        @media (max-width: $mobile) {
          bottom: 10px;
          left: 10px;
          padding: 10px;
        }
      }
    }
  
    .description-box {
      background: #f8f9fa;
      padding: 40px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
      @media (max-width: $mobile) {
        padding: 20px;
      }
  
      .transport-info {
        margin-bottom: 30px;
  
        h3 {
          font-size: 20px;
          color: #333;
          margin-bottom: 20px;
          position: relative;
          padding-left: 15px;
  
          @media (max-width: $mobile) {
            font-size: 18px;
            margin-bottom: 15px;
          }
  
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 18px;
            background-color: $primary-color;
          }
        }
  
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
  
          li {
            margin-bottom: 12px;
            color: #555;
            line-height: 1.6;
            padding-left: 20px;
            position: relative;
            font-size: 15px;
  
            @media (max-width: $mobile) {
              font-size: 14px;
              margin-bottom: 8px;
              line-height: 1.4;
            }
  
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 10px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $primary-color;
  
              @media (max-width: $mobile) {
                top: 8px;
                width: 4px;
                height: 4px;
              }
            }
  
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
  
      .notice {
        padding-top: 20px;
        border-top: 1px solid #ddd;
        margin-top: 20px;
  
        p {
          color: #666;
          font-size: 14px;
          line-height: 1.6;
          margin-bottom: 10px;
          word-break: keep-all;
  
          @media (max-width: $mobile) {
            font-size: 12px;
            line-height: 1.4;
          }
  
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }