@import './_variables.scss';

body {
  overflow-y: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
}

.section {
  height: 100vh;
  overflow: hidden;
  position: relative;
}