.third-content {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(160deg, #1a1a1a 0%, #262626 100%);
    padding: 80px 0;
    overflow: hidden;
  
    .location-header {
      text-align: center;
      margin-bottom: 60px;
      color: white;
  
      h2 {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 16px;
        background: linear-gradient(120deg, #ffffff, #cccccc);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
      p {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  
    .location-container {
      display: flex;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 40px;
      gap: 60px;
      align-items: center;
  
      .info-section {
        flex: 1;
        
        .info-item {
          background: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 24px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
          backdrop-filter: blur(10px);
          transition: all 0.3s ease;
  
          &:hover {
            background: rgba(255, 255, 255, 0.05);
            transform: translateX(5px);
          }
  
          .icon {
            color: #00ff9d;
            margin-right: 20px;
            stroke-width: 1.5;
          }
  
          h3 {
            color: white;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 8px;
          }
  
          p {
            color: rgba(255, 255, 255, 0.7);
            font-size: 16px;
          }
        }
  
        .map-button {
          width: 100%;
          padding: 20px;
          margin-top: 30px;
          border: none;
          border-radius: 12px;
          background: linear-gradient(120deg, #00ff9d, #00cc7d);
          color: #1a1a1a;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
  
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 10px 20px rgba(0, 255, 157, 0.2);
          }
        }
      }
  
      .map-section {
        flex: 1.2;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }
  
    @media (max-width: 1024px) {
      .location-container {
        flex-direction: column-reverse;
        padding: 20px;
  
        .map-section {
          width: 100%;
          margin-bottom: 40px;
        }
  
        .info-section {
          width: 100%;
        }
      }
    }
  }