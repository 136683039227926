@import '../../../shared/styles/variables';

.tab-image-container {
  width: 100%;
  max-width: $desktop;
  margin: 0 auto;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 20px 180px; 

  @media (max-width: $mobile) {
    min-height: calc(100vh - 60px);
    padding: 20px 15px 120px;
  }

  .tab-header {
    padding: 0 20px;
  }

  .title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    color: #111;
    letter-spacing: -0.5px;

    @media (max-width: $mobile) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ddd;
    }

    .tab-button {
      position: relative;
      padding: 12px 30px;
      font-size: 16px;
      border: none;
      background: none;
      color: #888;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;

      @media (max-width: $mobile) {
        padding: 10px 20px;
        font-size: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $primary-color;
        transform: scaleX(0);
        transition: transform 0.3s ease;
        z-index: 1;
      }

      &.active {
        color: $primary-color;

        &:after {
          transform: scaleX(1);
        }
      }

      &:hover:not(.active) {
        color: #333;
      }
    }
  }

  .content-scroll-container {
    flex: 1;
    overflow-y: auto;
    padding: 0 20px 120px;
    margin-top: 30px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    @media (max-width: $mobile) {
      padding: 0 15px 80px;
      margin-top: 20px;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  .image-container {
    width: 100%;
  }

  .images-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .content-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;

    @media (max-width: $mobile) {
      border-radius: 6px;
    }

    &:hover {
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    }
  }
}