.xi-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 1000;
 
  .header-content {
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
 
    .logo {
      color: white;
      text-decoration: none;
      font-size: 24px;
      font-weight: bold;
      white-space: nowrap;
    }
 
    .main-nav {
      display: flex;
      gap: 40px;
      height: 100%;
      
      .menu-item {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
 
        .menu-title {
          font-size: 16px;
          padding: 0 10px;
          height: 100%;
          display: flex;
          align-items: center;
          white-space: nowrap;
 
          &:hover {
            color: #ffd700;
          }
        }
 
        &:hover .sub-menu {
          display: block;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
 
        .sub-menu {
          position: absolute;
          top: 100%;
          left: 0;
          background: rgba(30, 30, 30, 0.95);
          min-width: 200px;
          display: none;
          opacity: 0;
          visibility: hidden;
          transform: translateY(20px);
          transition: all 0.3s ease;
          padding: 10px 0;
          
          .sub-item {
            display: block;
            color: white;
            text-decoration: none;
            padding: 12px 20px;
            font-size: 14px;
            white-space: nowrap;
            transition: all 0.2s ease;
 
            &:hover {
              background: rgba(255, 255, 255, 0.1);
              color: #ffd700;
            }
          }
        }
      }
    }
 
    .contact-number {
      font-size: 16px;
      white-space: nowrap;
 
      a {
        text-decoration: none;
        color: inherit;
      }
 
      .phone {
        color: #ffd700;
        font-weight: bold;
        font-size: 18px;
        margin-left: 8px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .xi-header {
    .header-content {
      .main-nav {
        gap: 20px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}