@import '../../../shared/styles/variables';

.sitePlan {
  width: 100%;
  max-width: $desktop;
  margin: 0 auto;
  padding: 80px 20px;

  @media (max-width: $mobile) {
    padding: 40px 15px;
  }

  .main-content {
    margin-bottom: 60px;

    @media (max-width: $mobile) {
      margin-bottom: 40px;
    }
  }

  .image-section {
    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      @media (max-width: $mobile) {
        border-radius: 4px;
      }
    }
  }
}