.xi-desktop-home {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  
    .carousel-container {
      position: relative;
      width: 100%;
      height: 100%;
  
      .slide {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #000;
      }
    }
  
    .thumbnail-nav {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      padding: 10px 0 120px;
      z-index: 10;
  
      .thumbnail {
        width: 120px;
        height: 67.5px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid transparent;
        transition: all 0.3s ease;
        position: relative;
  
        &.active {
          border-color: white;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .progress-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          background: white;
          z-index: 1;
        }
  
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  
  // footer와의 간격을 위한 미디어 쿼리 추가
  @media screen and (max-height: 800px) {
    .xi-desktop-home {
      .thumbnail-nav {
        bottom: 60px;
        height: 80px;
  
        .thumbnail {
        // 16:9 비율 유지
          width: 100px;
          height: 56.25px;
        }
      }
    }
  }