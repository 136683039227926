.xi-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #333;
  color: #999;
  font-size: 14px;

  
    .footer-content {
      max-width: 1400px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .basic-info {
        .divider {
          margin: 0 10px;
          color: #666;
        }
      }
  
      .expand-button {
        background: none;
        border: none;
        color: #999;
        cursor: pointer;
        padding: 8px;
        transition: all 0.3s ease;
  
        &:hover {
          color: white;
        }
  
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  
    .expanded-content {
      background-color: #2a2a2a;
      overflow: hidden;
  
      .detailed-info {
        max-width: 1400px;
        margin: 0 auto;
        padding: 20px;
  
        .info-row {
          margin-bottom: 10px;
  
          .divider {
            margin: 0 10px;
            color: #666;
          }
        }
  
        .warning-text {
          color: #777;
          margin: 15px 0;
        }
  
        .copyright {
          color: #666;
          margin-top: 15px;
        }
      }
    }
  }