
@import '../../../shared/styles/variables'; 
.일반공급 {
  width: 100%;
  max-width: $desktop;
  margin: 0 auto;
  padding: 80px 20px 120px;

  @media (max-width: $mobile) {
    padding: 60px 15px 80px;
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 60px;
    color: #333;
    position: relative;
    
    @media (max-width: $mobile) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 3px;
      background-color: $primary-color;

      @media (max-width: $mobile) {
        bottom: -15px;
        width: 40px;
        height: 2px;
      }
    }
  }

  .map-container {
    width: 100%;
    margin-bottom: 40px;
    
    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }

    .main-map {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}