@import '../../../shared/styles/variables';

.xi-mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  z-index: 1000;

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .menu-button {
      background: none;
      border: none;
      color: white;
      font-size: 26px;
      padding: 10px;
      cursor: pointer;
    }

    .logo {
      text-decoration: none; 
      font-weight: bold;
      color: white;
      font-size: 18px;
      margin: 0;
    }

    .phone-number {
      color: #FFD700;
      text-align: right;
      text-decoration: none;
      font-size: 14px;
      line-height: 1.2;
    }
  }

  .mobile-nav {
    background-color: #333;
    
    .menu-list {
      list-style: none;
      margin: 0;
      padding: 0;

      .menu-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .menu-title {
          display: flex;
          justify-content: space-between;
          padding: 15px;
          color: white;
          font-size: 16px;
          cursor: pointer;

          .plus-icon {
            color: #FFD700;
          }
        }

        .sub-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          background-color: #444;

          li {
            a {
              display: block;
              padding: 12px 25px;
              color: #ccc;
              text-decoration: none;
              font-size: 14px;

              &:active {
                background-color: #555;
              }
            }
          }
        }
      }
    }
  }
}
