@import '../../../shared/styles/variables';

.xi-mobile-footer {
  background-color: #333;
  padding: 20px 0;
  width: 100%;

  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    background-color: #444;
    margin-bottom: 20px;

    .footer-link {
      text-decoration: none;
      color: #fff;
      padding: 15px 10px;
      text-align: center;
      background-color: #333;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      word-break: keep-all;
      line-height: 1.2;

      &:active {
        background-color: #444;
      }
    }
  }

  .footer-info {
    padding: 0 20px;
    text-align: center;

    .address {
      color: #999;
      font-size: 12px;
      margin-bottom: 8px;
      word-break: keep-all;
      line-height: 1.4;
    }

    .copyright {
      color: #777;
      font-size: 11px;
    }
  }
} 